<template>
    <div id="app" v-cloak>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss">
#app,
body {
    width: 100%;
    height: 100%;
    background: #fbfbfb;
    color: #333;
    position: relative;
    font-family: "Microsoft YaHei";
}
html,
body {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 375px;
}
.flex {
    display: flex;
}
[v-cloak] {
    display: none;
}
p {
    margin: 0;
}
div {
    box-sizing: border-box;
}
.flex {
    display: flex;
}
:root:root {
    --van-nav-bar-icon-color: #333;
    --van-nav-bar-text-color: #333;
}
</style>
