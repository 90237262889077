import { createRouter, createWebHistory } from "vue-router";
import Index from "../views/index/index.vue";

const routes = [
    {
        path: "/:id",
        component: Index,
    },
    //首页
    {
        path: "/",
        name: "index",
        component: Index,
    },
    //我的订单
    {
        path: "/order",
        name: "order",
        component: () => import("../views/index/order.vue"),
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: "hash",
    routes,
});
router.beforeEach((to, from, next) => {
    if (from.name) {
        to.meta.from = from;
    }
    next();
});

export default router;
