import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入vant
import Vant from "vant";
import "vant/lib/index.css";
import { showToast, showDialog, showConfirmDialog, showLoadingToast, showSuccessToast, showFailToast } from "vant";
import { showImagePreview } from "vant";

//引入request.js
import http from "../src/assets/js/request.js";

//全局mixin
import { globalMixin } from "./assets/js/mixin.js";

//引入点击复制
import Clipboard from "v-clipboard";

const app = createApp(App);
app.use(store);
app.use(router);
app.config.globalProperties.$showToast = showToast;
app.config.globalProperties.$showDialog = showDialog;
app.config.globalProperties.$showConfirmDialog = showConfirmDialog;
app.config.globalProperties.$showLoadingToast = showLoadingToast;
app.config.globalProperties.$showSuccessToast = showSuccessToast;
app.config.globalProperties.$showFailToast = showFailToast;
app.config.globalProperties.$showImagePreview = showImagePreview;
app.use(Vant);
app.use(http);
app.use(Clipboard);
app.mixin(globalMixin);
app.mount("#app");
